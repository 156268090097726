<!-- =========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
========================================================================================== -->

<template>
  <div id="data-list">
    <vs-popup
      classContent="popup-example"
      title="Confirm Update"
      :active.sync="popupActive"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form autocomplete="off">
          <validation-provider
            name="password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Password"
              v-model="password"
              class="w-full mt-6"
            />
          </validation-provider>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="managerReplacement"
            class="mb-2 mt-5"
            >Confirm</vs-button
          >
        </form>
      </validation-observer>
    </vs-popup>
    <vx-card
      id="div-loading"
      ref="filterCard"
      title="Manager replacement"
      class="user-list-filters mb-8 vs-con-loading__container h-full"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
      style="overflow: unset"
    >
      <validation-observer ref="observer">
        <form slot-scope="{ validate }">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Current directmanager
              </label>
              <validation-provider
                name="current_directmanager_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="(FullName) => FullName.id"
                  label="FullName"
                  :options="userList"
                  name="current_directmanager_id"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="current_directmanager_id"
                  class="mb-4"
                  placeholder="User"
                  :required="!!errors[0]"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>New directmanager
              </label>
              <validation-provider
                name="new_directmanager_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="(FullName) => FullName.id"
                  label="FullName"
                  :options="userList"
                  name="new_directmanager_id"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="new_directmanager_id"
                  class="mb-4"
                  placeholder="User"
                  :required="!!errors[0]"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div>
          </div>

          <vs-button
            type="filled"
            @click="validate().then((valid) => (valid ? openPopUp() : ''))"
            class="mb-2"
            >SUBMIT</vs-button
          >
        </form>
      </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import vSelect from "vue-select";
import endpoints from "../../../endpoints";

import moduleEmployeeManagement from "@/store/get-all-employees/moduleEmployeeManagement.js";

import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      city_name: null,
      popupActive: false,
      userList: [],
      current_directmanager_id: null,
      new_directmanager_id: null,
      password: null,
    };
  },
  methods: {
    openPopUp() {
      this.popupActive = true;
    },
    managerReplacement() {
      const formData = new FormData();
      formData.append(
        "current_directmanager_id",
        this.current_directmanager_id
      );
      formData.append("new_directmanager_id", this.new_directmanager_id);
      formData.append("password", this.password);

      axios
        .post(`${endpoints.API_URL}managerReplacement`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.popupActive = false;
          if (response.status == 200) {
            this.$vs.notify({
              title: "Success",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.clearData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.popupActive = false;
          this.$vs.notify({
            title: "Error",
            text: error.response.data.errors[0],
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    goToCityList() {
      this.$router.push("/GetAll/City");
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.current_directmanager_id = null;
      this.new_directmanager_id = null;
      this.password = null;
    },
    clearData() {
      this.current_directmanager_id = null;
      this.new_directmanager_id = null;
      this.password = null;
    },
    fetchItemsData() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6,
      });
      this.$store
        .dispatch("employees/getAlls", {})
        .then((response) => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");
          this.userList = response.data;
        })
        .catch((err) => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Error",
            text: err,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
  mounted() {
    this.fetchItemsData();
  },
  created() {
    if (!moduleEmployeeManagement.isRegistered) {
      this.$store.registerModule("employees", moduleEmployeeManagement);
      moduleEmployeeManagement.isRegistered = true;
    }
  },
};
</script>

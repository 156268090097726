var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"data-list"}},[_c('vs-popup',{attrs:{"classContent":"popup-example","title":"Confirm Update","active":_vm.popupActive},on:{"update:active":function($event){_vm.popupActive=$event}}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"autocomplete":"off"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-6",attrs:{"danger":!!errors[0],"danger-text":errors[0],"type":"password","name":"password","icon-no-border":"","icon":"icon icon-lock","icon-pack":"feather","label-placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('vs-button',{staticClass:"mb-2 mt-5",attrs:{"disabled":invalid,"type":"filled"},on:{"click":_vm.managerReplacement}},[_vm._v("Confirm")])],1)]}}])})],1),_c('vx-card',{ref:"filterCard",staticClass:"user-list-filters mb-8 vs-con-loading__container h-full",staticStyle:{"overflow":"unset"},attrs:{"id":"div-loading","title":"Manager replacement","collapse-action":"","refresh-content-action":""},on:{"refresh":_vm.resetColFilters}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('form',{},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("Current directmanager ")]),_c('validation-provider',{attrs:{"name":"current_directmanager_id","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-4",attrs:{"reduce":function (FullName) { return FullName.id; },"label":"FullName","options":_vm.userList,"name":"current_directmanager_id","clearable":false,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"placeholder":"User","required":!!errors[0]},model:{value:(_vm.current_directmanager_id),callback:function ($$v) {_vm.current_directmanager_id=$$v},expression:"current_directmanager_id"}}),_c('span',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!errors[0]),expression:"!!errors[0]"}],staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"},[_c('span',{staticClass:"span-text-validation"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("New directmanager ")]),_c('validation-provider',{attrs:{"name":"new_directmanager_id","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-4",attrs:{"reduce":function (FullName) { return FullName.id; },"label":"FullName","options":_vm.userList,"name":"new_directmanager_id","clearable":false,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"placeholder":"User","required":!!errors[0]},model:{value:(_vm.new_directmanager_id),callback:function ($$v) {_vm.new_directmanager_id=$$v},expression:"new_directmanager_id"}}),_c('span',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!errors[0]),expression:"!!errors[0]"}],staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"},[_c('span',{staticClass:"span-text-validation"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1)]),_c('vs-button',{staticClass:"mb-2",attrs:{"type":"filled"},on:{"click":function($event){validate().then(function (valid) { return (valid ? _vm.openPopUp() : ''); })}}},[_vm._v("SUBMIT")])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }